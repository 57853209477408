<template>
  <div class="travelCateList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>店铺列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>店铺名称:</span>
      <el-input
        v-model="list.storeName"
        placeholder="请输入店铺名称"
      ></el-input>
      <span>店铺ID:</span>
      <el-input v-model="list.storeId" placeholder="请输入店铺ID"></el-input>
      <span>店铺状态:</span>
      <el-select
        placeholder="请选择状态"
        style="margin:0 2.5rem 0 1.25rem"
        v-model.trim="list.storeStatus"
        @change="onChangeStatus"
      >
        <el-option :key="0" label="全部" :value="0"></el-option>
        <el-option :key="1" label="营业中" :value="1"></el-option>
        <el-option :key="2" label="禁用" :value="2"></el-option>
      </el-select>
      <el-button type="primary" @click="getList()">查找</el-button>
      <el-button
        type="primary"
        @click="$router.push('/selectMall/shop/add/0')"
        v-if="$store.state.powerList.indexOf('selectMall:shop:list:add')!==-1"
      >
        添加
      </el-button>
    </div>
    <el-table :data="tableData" style="width:100%" border>
      <el-table-column
        align="center"
        prop="storeSort"
        label="排序"
        width="width"
      >
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.storeSort"
            ref="saveTagInput"
            size="small"
            @input="onInputInt"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.storeSort }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="storeId"
        label="店铺ID"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="storeName"
        label="店铺名称"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="storeName"
        label="店铺封面"
        width="width"
      >
        <template slot-scope="{ row }">
          <el-image
            style="width:80px; height:80px"
            :src="row.storeHomeImg"
            :preview-src-list="[row.storeHomeImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="storeAddr"
        label="店铺地址"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="storeStatus"
        label="店铺状态"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="storeTel"
        label="店铺电话"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="userName"
        label="店铺联系人"
        width="width"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="mini"
            @click="toEdit(row)"
            v-if="
              $store.state.powerList.indexOf('selectMall:shop:list:edit')!==-1
            "
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="onModify(row, row.storeStatus=='禁用'?1 :2)"
            v-if="
              $store.state.powerList.indexOf('selectMall:shop:list:modify')!==
              -1
            "
            >{{ row.storeStatus=="禁用"?"启用" :"禁用" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { shopList, shopEdit, shopModify } from "../../api/selectMall";
export default {
  name:"selectMallshop",
  data() {
    return {
      list:{
        currentPage:1,
        pageSize:5,
        storeName:undefined,
        storeStatus:undefined,
      },
      tableData:[],
      selection:[],
      pagination:{},
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods:{
    onInputInt(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value)&&!reg2.test(value)) {
        return this.$message({
          type:"warning",
          message:"请输入整数!",
        });
      }
    },
    async getList() {
      let newList = {};
      for (var k in this.list) {
        let item = this.list[k];
        if (item!==""||item!==undefined) newList[k] = item;
      }
      const { data } = await shopList(newList);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    onChangeStatus(e) {
      e?(this.list.storeStatus = Number(e)) :"";
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async handleInputConfirm(row) {
      const { data } = await shopEdit({
        ...row,
        storeSort:Number(row.storeSort),
      });
      if (data.code!==0) {
        this.$message({
          message:"设置失败",
          type:"error",
        });
      } else {
        this.$message({
          message:"设置成功",
          type:"success",
        });
      }
      row.inputVisible = false;
    },
    toEdit(row) {
      this.$store.commit("setMallShopData", row);
      this.$router.push("/selectMall/shop/add/" + row.storeId);
    },
    async onModify(row, status) {
      const { data } = await shopModify({
        ids:row.storeId,
        status,
      });
      if (data.code==0) {
        this.getList();
        this.$message({
          message:"设置成功",
          type:"success",
        });
      } else {
        this.$message({
          message:"设置失败",
          type:"error",
        });
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.travelCateList {
  .el-breadcrumb {
    height:35px;
    border-bottom:1px solid rgb(192, 191, 191);
  }

  .search {
    margin-top:50px;

    .el-input {
      width:200px;
      margin:0 40px 0 20px;
    }
  }

  .el-table {
    margin-top:30px;

    .el-table__body-wrapper {
      table {
        td {
          .cell {
            img {
              width:80px;
              height:80px;
            }
          }
        }
      }
    }
  }

  /deep/.el-dialog {
    .el-form-item__content {
      display:flex;

      .img {
        position:relative;
        height:100px;
        width:100px;
        margin:20px 20px;

        img {
          height:100px;
          width:100px;
          border:1px solid #c0ccda;
        }

        div {
          position:absolute;
          top:0;
          right:2px;
          height:10px;
          width:10px;
          z-index:100;
        }
      }

      .addImg {
        display:inline-block;
        width:100px;
        height:100px;
        border:1px dashed #c0ccda;
        text-align:center;
        line-height:100px;
        cursor:pointer;
        margin-top:20px;

        i {
          width:50px;
          height:50px;
          font-size:20px;
          color:#c0ccda;
        }
      }
    }
  }

  .el-pagination {
    margin-top:30px;
    float:right;
  }
}
</style>
